.mat-success,[color=success]{
    color: #16C98D !important; 
}

.mat-warning,[color=warning]{
 color: #FFC20E !important; 
}

[color=secondary]{
    color: #00448D !important; 
   }
   [color=gray]{
    color: #808285 !important; 
   }


.mat-danger,[color=danger]{
    color: #FA5E5B !important; 
    }
    .color-danger{
        color: #FA5E5B; 
    }

.success{
    background-color: #00C389 !important; 
}

.success-text-black{
    background-color: #00C389 !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.warning{
 background-color: #FFC20E !important; 
 color: #292929 !important;
}

.warning-orange{
    background-color: #FF7F41 !important; 
    color: #292929 !important;
    font-weight: 600 !important;
   }

.danger{
 background-color: #FA5E5B !important; 
}

.danger-text-black{
    background-color: #FF7F41 !important;
    color: #292929 !important;
    font-weight: 600 !important;
}

.blue{
background-color: #59CBE8 !important;
}

.blue-text-black{
    background-color: #59CBE8 !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.gris{
background-color:  #E6E7E8 !important;
color: #454648 !important;
border: 2px solid rgba(167,169,172,1) !important;
}

.capital-grey {
    background-color: transparent !important;
    color: #2C2A29 !important;
    border: 1px solid #2C2A29 !important;
}

.capital-dark {
    background-color: #2C2A29 !important;
}

.dark{
    background-color:  #747779 !important;
    color: #ffffff !important;
    }

.white{
    background-color:  #ffffff !important;
    color: #292929 !important;
    border: 2px solid rgba(167,169,172,1);
    }

.white-outerline{
    background-color: #ffffff !important;
    color: #292929 !important;
    border: 2px solid #A7A9AC !important;
    
    }

.gris-outerline{
 background-color: #E6E7E8 !important;
 color: #292929 !important;
 border: 2px solid #A7A9AC !important;
 
}

.inactivo{
 background-color: #737779 !important;
 color:  #FFFFFF;
}

.blue-outerline{
 color: #00448D !important;
 border: 2px solid #00448D !important;
 background-color: transparent !important;
}

.blue-outerline-white{
    color: #00448D !important;
    border: 2px solid #00448D !important;
    background-color: #ffffff !important;
   }


.transparent{
background-color:  #ffffff !important;
color: #454648 !important;
}

.text-link-blue{
  color: #00448D !important;
  cursor: pointer;
}

.text-link-black{
  color: #000 !important;
  cursor: pointer;
}


[color=danger]{
    color: #FA5E5B !important;
}