.scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 4px;
	background-color: #D1D3D4;
}

.scroll::-webkit-scrollbar
{
	width: 4px;
	background-color: #D1D3D4;
}

.scroll::-webkit-scrollbar-thumb
{
	border-radius: 4px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #747779;
  height: 20px;
}

.scroll::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 14px; 
}

.scroll::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 14px;
}

.scroll{
    overflow-y: auto;
}