/*
.bc-button {
    height: 40px;
    width: 100%;
    border-radius: 10000px;
    background-color: #FFC20E;
    border: 0px;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.38px;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    color: black;
}

.bc-button-medium{
    width: 90%;
    height: 48px;
    font-size: 20px;
}

.bc-button:disabled{
    background-color: rgba(165, 167, 168);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    color: gray;
    cursor: not-allowed;
}

.bc-button:hover:enabled {
    background-color: rgb(255, 219, 51);

}

.bc-button:focus {
    background-color: rgb(255, 210, 0);
    outline: none;
}

.bc-button:active {
    background-color: rgb(242, 199, 0);
}


.bc-button-secondary {
    height: 40px;
    width: 100%;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.38px;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    color: #00448D;
    border: 1.5px solid #00448D ;
    border-radius: 10000px;
    background-color: rgba(255,255,255,0);
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.16), 0 1px 3px 0 rgba(0,0,0,0.1);
    padding-left: 30px;
    padding-right: 30px;
}


.bc-button-secondary:disabled{
    opacity: 0.65;
    cursor: not-allowed;
}

.bc-button-secondary:hover:enabled {
    box-shadow: 0 1px 8px 0 rgba(0,0,0,0.14), 0 3px 8px 0 rgba(0,0,0,0.11);


}

.bc-button-secondary:focus {
    background-color: rgba(255,255,255,0);
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.16), 0 1px 3px 0 rgba(0,0,0,0.1);

}

.bc-button-secondary:active {
    background-color: rgba(0,0,0,0.05);
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.16), 0 1px 3px 0 rgba(0,0,0,0.1);
  
}
*/