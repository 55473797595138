
 .mat-mdc-tab-group{
    width: 100%;
}

.mat-mdc-tab-label{
  color: #2C2A29 !important;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  text-align: center;
  font-weight: normal !important;

}

 .mat-mdc-tab-label-active{
  opacity: 1 !important;
  color: #2C2A29 !important;
  font-family: "Open Sans"!important;
  font-size: 16px;
  font-weight: bold !important;
  letter-spacing: -0.3px;
  line-height: 24px;
  text-align: center;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: #2C2A29 !important;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  text-align: center;
  font-weight: normal !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  font-weight: bold !important;
}

.mat-mdc-tab-labels{
  width: 34%;
}