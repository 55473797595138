 /*style time Line*/

 .timeline {
    list-style: none;
    padding: 20px;
    padding-bottom: 0px;
    position: relative;
    }

    
    .timeline:before {
        top: 30px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 1.5px;
        height: 80%;
        background-color: #eee;
   }

   
   .timeline .timeline-item {
    margin-bottom: 20px;
    position: relative;
}



   .timeline .timeline-item .timeline-badge {
    color: #fff;
    width: 12px;
    height: 12px;
    line-height: 52px;
    font-size: 22px;
    text-align: center;
    position: absolute;
    top: 4px;
    left: 0;
    margin-left: -5.5px;
    background-color: #747779;
    border: 3px solid #747779;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.time-line-badge-active{
   width: 16px !important;
   height: 16px !important;
   background-color: #FFD200 !important;
   border: 0.5px solid #FFD200 !important;
   margin-left: -7px !important;
   box-shadow:inset 0 0 0 1px #ffffff;
}

.timeline-panel{
    margin-left: 30px;
}
