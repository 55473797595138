/*@import url('https://fonts.googleapis.com/css?family=Numans');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
*/

/*(@import 'https://galatea-qa.apps.ambientesbc.com/bds.min.css';*/

/*@import 'https://galatea-qa.apps.ambientesbc.com/0.0.4-beta/bds.min.css';*/

@import "https://library-sdb.apps.bancolombia.com/bds/6.25.3/bds.min.css";
/*clases personalizadas de botones tabs, tags , tablas y colores*/

@import "./custom-css/index.css";
/* fuentes utilizadas**/

@font-face {
    font-family: CIBFontSans-Bold;
    src: url(assets/fonts/CIBFontSans-Bold.ttf) format("truetype");
}

@font-face {
    font-family: CIBFontSans-Light;
    src: url(assets/fonts/CIBFontSans-Light.ttf) format("truetype");
}

@font-face {
    font-family: CIBFontSerif-Bold;
    src: url(assets/fonts/CIBFontSerif-Bold.ttf) format("truetype");
}

@font-face {
    font-family: Nunito;
    src: url(assets/fonts/Nunito-Regular.ttf) format("truetype");
}

@font-face {
    font-family: Nunito-bold;
    src: url(assets/fonts/Nunito-Bold.ttf) format("truetype");
}

/* para que los h1 se puedan centrar*/

h1,
h2,
h3,
h4 {
    width: auto !important;
}

/* para que las ayudas en los campos de texto queden con el size correcto*/

.bc-span-info {
    font-size: 12px;
}

/* en los nuevos navegadores cuando un elemento html tiene el focus este
ubica un outline , este se elimina asi..
*/

*:focus {
    outline: none;
}

/*propiedad para que los input respeten el color de fondo*/

input {
    background-color: transparent;
}

.btn-fab-xss {
    width: 30px;
    height: 26px;
    border-radius: 8275.86px;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
}

.btn-fab-xss > * {
    font-size: 12px !important;
}

.btn-xss {
    width: 26px !important;
    height: 26px !important;
}

.btn-xss > * {
    font-size: 12px !important;
}

.aling-right {
    text-align: right;
}

.container {
    display: block !important;
}

/*
.bc-form-field{
    margin-top: 20px;
}
*/

.separate-bottom {
    margin-bottom: 30px;
}

.padding-bottom {
    padding-bottom: 30px;
    margin-right: auto;
}

.title > p {
    color: #808285;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: -0.17px;
    line-height: 16px;
    text-align: left;
}

bc-alert {
    width: 96%;
    margin-left: 2%;
    margin-top: 1%;
    position: fixed;
    z-index: 9999999999;
}

.bc-modal-active {
    z-index: 1000 !important;
}

.no-link {
    text-decoration: none !important;
}

.container-mat-mdc-select-not-border-top .mat-mdc-form-field-infix {
    border-top: unset !important;
}

.mat-mdc-menu-panel {
    padding: 0 10px !important;
    max-width: unset !important;
}

.margin-bottom-none {
    margin-bottom: 0 !important;
}

.button-add-files {
    max-width: 260px !important;
    margin: unset !important;
}

.custom-file-input {
    display: none;
}

.ciiu-codes.mat-mdc-tooltip {
    font-size: 18px !important;
}

.expand-select-list.mat-mdc-select-panel {
    min-width: calc(100% + 250px) !important;
}
.economic-activity-selector .bc-form-field {
    margin-top: 0px !important;
}
.mat-mdc-tab-tab > mat-tab-header > .mat-mdc-tab-label-container > div > div > div {
    height: 27px;
    min-height: 5px;
    margin: 3px;
    width: 90px;
    min-width: 5px;
}

mat-tab-group.capital-theme {
    width: auto;
}

mat-tab-group.capital-theme mat-tab-header.mat-mdc-tab-header {
    border: none !important;
}

mat-tab-group.capital-theme .mat-mdc-tab-label {
    color: black !important;
}

mat-tab-group.capital-theme div.mat-mdc-tab-label {
    border-bottom: 1px solid black;
}

mat-tab-group.capital-theme.mat-primary .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled) {
    background-color: unset;
}

.no-margin {
    margin: 0px !important;
}

.no-padding {
    padding: 0 !important;
}

.margin-rigth {
    margin-right: 5px;
}

main.capital-theme .mat-mdc-chip-list-wrapper {
    margin: unset !important;
}

.bc-button-secondary.capital-theme {
    color: #000000;
    border: 1.5px solid #000000;
}

.bc-button.capital-theme {
    background-color: #fdda24;
}

.bc-button.capital-theme:disabled {
    background-color: rgba(218, 218, 218, 0.4);
    color: rgba(44, 42, 41, 0.4);
}

th.capital-theme {
    text-align: left !important;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.3px;

    color: #000000;
}

.mat-mdc-sort-header-arrow {
    opacity: 1 !important;
}

[mat-sort-header].cdk-keyboard-focused .mat-mdc-sort-header-container,
[mat-sort-header].cdk-program-focused .mat-mdc-sort-header-container {
    border-bottom: unset !important;
}

.capital-card {
    background-color: white;
    width: 50%;
    margin: auto;
    min-width: 400px;
    max-width: 700px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
}

.bc-alert-title {
    color: #FFF !important;
    font-weight: 600 !important;
    margin-bottom: -8px !important;
}

#sales-room-alerts {
    background-color: #2C2A29;
}

#sales-room-alerts > section > button > em {
    color: #FFF !important;
    margin-top: 10px;
}
