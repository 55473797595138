table.table-separate-row{
    border-collapse: separate !important;
    border-spacing: 0 2px !important;
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }



  table.table-separate-row tr{
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 0 0 5px 0 rgba(0,0,0,0.1);
    height: 78px !important;
    cursor: pointer;
  }
  
  table.table-separate-row td.mat-mdc-cell {
    padding: 8px;
    color: #292929;	
    font-family: "Open Sans";	
    font-size: 14px;
    letter-spacing: -0.26px;	
    line-height: 18px;
    vertical-align: middle;
  }

  table.table-separate-row td.mat-mdc-cell > span.small{
    color: #747779;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: -0.17px;
  }

  table.table-separate-row  td.mat-mdc-cell > span.badge{
   display: inline;
   border-radius: 10000px;
  }
  table.table-separate-row tr.mat-mdc-header-row{
    background-color: #E6E7E8 !important;
    
    }

    table.table-separate-row .th-aling-right{
    display: flex;
    padding: 31px 0;
    justify-content: center;
    }
