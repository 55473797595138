
mat-chip{
    color: #FFFFFF !important;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 18px;
    text-align: center; 
    height: 32px;
    cursor: pointer;
    overflow: hidden;
  }
  mat-chip>svg{
      margin-right: 5px;
      height: 16px;
  }

  mat-chip>bc-icon{
    margin-right: 5px;
    height: 16px;
}
.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
  display: none;
}

.chip-inactive{
  --mdc-chip-label-text-color: #212121 !important;
}

.chip-active{
  --mdc-chip-label-text-color: white !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
  padding-left: 12px !important;
}

.mdc-evolution-chip {
  max-width: fit-content !important;
}