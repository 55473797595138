
.mat-mdc-table{
    width: 100%;
    background-color:  transparent !important;
}

.mat-mdc-header-row{
    height: 74px !important;
    border-radius: 5px 5px 0 0 !important;
    background-color: #F7F7F7 !important;
    
    }
.mat-mdc-sort-header-container{
    display: flex !important;
    justify-content: left !important;
}
.mat-mdc-sort-header-button, .mat-mdc-sort-header-content{
    text-align: left !important;
}
td.mat-mdc-cell:first-of-type{
    padding-left: 50px !important;
}
th.mat-mdc-header-cell:first-of-type {
    padding-left: 50px !important;
}
td.mat-mdc-cell{
    text-align: left !important;
    padding-left: 5px !important;
}
.mat-mdc-header-cell {
    color: #292929 !important;
    font-family: "Open Sans" !important;
    font-size: 14px ;
    font-weight: bold !important;
    letter-spacing: -0.26px;
    line-height: 16px;
    text-align: right !important;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-left: 8px;
    padding-right: 8px;
}

.bc-table-container .bc-table-content .bc-table thead tr th.bc-table-number-column, 
.bc-table-container .bc-table-content .bc-table thead tr th.bc-table-total-column {
    text-align: left;
}

.bc-table-container .bc-table-content .bc-table thead tr th .bc-icon {
    left: 79%;
}