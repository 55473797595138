.mat-mdc-form-field {
    width: 100%;
}

.mat-mdc-form-field-label {
    color: #808285 !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    letter-spacing: -0.2px !important;
    line-height: 18px !important; 
}

.mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
}

.mat-mdc-select-arrow {
    border-left: 15px solid transparent !important;
    border-right: none !important;
    border-top: 15px solid transparent !important;
    border-image-source: url('../assets/images/chevron-down.svg') !important;
    border-image-repeat: stretch !important;
}

.select-dropdown {
    margin-top: 27px !important;
}

.mat-focused .mat-mdc-select-arrow {
    border-image-source: url('../assets/images/chevron-up.svg') !important;
    border-image-repeat: stretch !important;
}

.mat-mdc-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42) !important;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
}

.mat-mdc-select-arrow svg {
    display: none;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #000000;
}

.mdc-text-field{
    padding: 0 !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #808285 !important;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--mdc-theme-primary, #000000);
}

.select-dropdown {
    margin-top: 0 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
    padding-top: 5px;
    padding-bottom: 5px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-bottom: 0;
}

.mat-mdc-form-field-infix {
    min-height: 39px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-bottom: 0;
}

.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent !important;
}